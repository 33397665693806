import { useState, useEffect } from "react";
import { IP } from './config.js';
import { useNavigate } from 'react-router-dom';
import bg from "./bg.png";


const Home = () => {
    const [msg, setMsg] = useState("");
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function chat() {
      setLoading(true);
      fetch(IP + "/chat", {
        method: "POST",
        mode: 'cors',
        credentials: 'include',
        headers: { "Authorization" : 'Basic',
                   "Content-Type" : 'application/json',
                   "Access-Control-Allow-Origin": 'true'},
        body: JSON.stringify({"data": msg})
      }).then((resp) => {
        resp.json().then((data) => {
          setLoading(false);
          console.log(msg, data);
          setMessages([...messages, msg, data['response']]);
          setMsg("");
        });
      });
    }

    function handleKeyPress(evt) {
      if (evt.key === 'Enter') {
        chat();
      }
    }

    useEffect(() => {
    }, []);

    return (
        <div className="home">
        <img src={bg} />

        <div className="card bg-base-100 shadow-xl chat-window px-5 py-5">
            <div className="alert alert-primary shadow-lg">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                <span>Dieser Kaufberater kennt den Handy-Produktkatalog. Dies ist ein <b>POC</b>.</span>
              </div>
            </div>

{ messages.length > 0 ?
            <div className="divider"></div>
: ""}

{ messages.map(function (message, index) {
    return (
            <>
{ index % 2 === 0 ?
              <div className="chat chat-end">
                <div className="chat-bubble chat-bubble-primary">{ message }</div>
              </div>
:
              <div className="chat chat-start">
                <div className="chat-bubble chat-bubble-secondary">{ message }</div>
              </div>
}
            </>
    );
})}

            <div className="divider"></div>

            <div className="form-control">
              <div className="input-group">
                <input placeholder="Frage stellen" className="input input-bordered w-full max-w-xs"
                    required
                    value={ msg }
                    onChange={(e) => setMsg(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                />
                <button className={`btn btn-circle btn-primary ` + (loading ? "loading btn-disabled" : "")} onClick={() => chat()}>
    { !loading ?
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
    : ""}
                </button>
              </div>
            </div>
        </div>
        </div>
    ); 
}
 
export default Home;
